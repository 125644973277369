.search {
  position: relative;
  display: flex;
  margin-bottom: -6rem;
}

#searchlist{
  width: 60%;
  position: fixed;
  background-color: white;
  padding-left: 16rem;
  padding-right: 16rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-left: -16rem;
}

.searchTerm {
  width: 100%;
  border: 0.17rem solid #888;
  padding: 5px;
  height: 3rem;
  border-radius: 5px;
  outline: none;
  color: #888;
}

.searchTerm:focus{
  color: #FC9855;
  border: 0.2rem solid #FC9855;
  font-weight:500;
}

.wrapChants{
  width: 30%;
  position: absolute;
  top: 12rem;
  left: calc((65% + 18rem) / 2);
}

.wrapDay{
  width: 20%;
  position: absolute;
  top: 14rem;
  left: 50%;
  margin-left: calc((100vw - 70%) / 2);
}

#cards{
  margin-top: 8rem;
}
