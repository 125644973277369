input[type=file]::file-selector-button, .button-5 {
  align-items: center;
  background-clip: padding-box;
  background-color: #FC9855;
  border: 1px solid transparent;
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui,-apple-system,system-ui,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
}

input[type=file]::file-selector-button{
  margin-right: 3rem;
}

input[type=file]::file-selector-button:hover, .button-5:hover{
  background-color: #FDA972;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

input[type=file]::file-selector-button:hover, .button-5:hover {
  transform: translateY(-1px);
}

input[type=file]::file-selector-button:active, .button-5:active {
  background-color: #c85000;
  box-shadow: rgba(0, 0, 0, .06) 0 2px 4px;
  transform: translateY(0);
}
