.layout{
  max-width: 25%;
  margin-left: 5%;
  margin: 8rem;
}
.input-group{
  display: flex;
  flex-direction: column;
}
.input-group input{
  padding:15px;
}

.btn{
  background-color: #888CF0;
  color:#000000;
  margin-top: 20px;
  box-shadow: none;
  border:none;
  padding:15px;
  cursor: grabbing;
  font-size: 20px;
}

.list-container{
  margin-top:40px;
  display: flex;
  flex-direction: column;
}
.list-item{
  font-size: 16px;
  cursor:grabbing;
}
.list-item i{
  margin-right:20px;
  font-size: 30px;
}
h3{
  font-size: 30px;
}
