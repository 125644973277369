.cardSq {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  /* 5px rounded corners */
  top: 0;
  overflow: hidden;
  width: 13rem;
  margin: 3rem;
  font-size: 16px;
  word-wrap: break-word;
  cursor: pointer;
}

.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  /* 5px rounded corners */
  top: 0;
  overflow: hidden;
  width: 150%;
  height: 4rem;
  margin: 1rem;
  font-size: 16px;
  word-wrap: break-word;
  cursor: pointer;
  display: flex;
  background-color: white;
}

#cards {
  width: 100%;
  margin-left: calc((150% - 100%) / -2)
}

/* On mouse-over, add a deeper shadow */
.card:hover,
.cardSq:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

/* Add some padding inside the card container */
.container {
  padding: 16px 22px;
}

img {
  border-radius: 5px 5px 0 0;
  object-fit: cover;
}

body {
  overflow-x: hidden;
}

/* The Overlay (background) */
.overlay {
  /* Height & width depends on how you want to reveal the overlay (see JS below) */
  height: 0%;
  width: 100%;
  position: fixed;
  /* Stay in place */
  z-index: 10;
  /* Sit on top */
  left: 0;
  top: 0;
  background-color: #f1f1f1;
  overflow-x: hidden;
  /* Disable horizontal scroll */
  transition: 0.5s;
  /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
}

/* Position the content inside the overlay */
.overlay-content {
  position: relative;
  top: 0;
  /* 25% from the top */
  width: 100%;
  /* 100% width */
  text-align: center;
  /* Centered text/links */
  margin-top: 30px;
  /* 30px top margin to avoid conflict with the close button on smaller screens */
}

.dayImage {
  height: 104px;
}

.overlay h2 {
  padding: 8px;
  text-decoration: none;
  font-size: 3rem;
  color: #333;
}

#textBox{
  width: 300px;
  border: 1px solid #b8b8b8;
  padding: 0 4px;
}

.tagsSuggestionList{
  height: 100px;
  overflow: auto;
  width: 300px;
  margin: 4px auto;
}

.tagSuggestion {
  background-color: #e2e2e2;
  padding: 4px;
  border-radius: 6px;
  margin: 4px auto;
}

.tagSuggestion:hover{
  background-color: #d2d2d2;
  cursor: pointer;
}

.uploadButtonContainer {
  width: max-content;
  display: flex;
  align-items: center;
  gap: 12px;
}

.h2_container {
  margin: 0 196px;
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  justify-content: center;
}

#editChantTitle:hover {
  cursor: pointer;
}

/* Position the close button (top right corner) */
.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
  z-index: 10;
}

/* When the height of the screen is less than 450 pixels, change the font-size of the links and position the close button again, so they don't overlap */
@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 20px
  }

  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}

.pdfFrame {
  height: 80rem;
  width: 100%;
  margin-top: 2rem;
}


label.calendar {
  overflow: hidden;
  user-select: none;
  cursor: pointer;
}

label.calendar input {
  position: absolute;
  bottom: 100%;
  left: 0;
  border: 0;
  padding: 0;
  outline: none;
}

.audioFiles,
.audioFilesUpload {
  user-select: none;
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: center;
  margin: 16px;
}

.audioFileUploaded,
.buttonFileUpload {
  opacity: 1 !important;
}

.audioFile,
.buttonFileUpload {
  opacity: 0.4;
  background-color: #e2e2e2;
  padding: 16px;
  border-radius: 6px;
  display: flex;
  gap: 8px;
}

.audioFileUploaded:hover,
.buttonFileUpload:hover {
  background-color: #d2d2d2 !important;
  cursor: pointer;
}

.audioFile img,
.buttonFileUpload img {
  width: 24px;
}

#audioPlayer {
  margin: 0 auto;
}

#chantTags {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin: 4px auto;
}

.tag {
  color: #141313;
  background-color: #f1ece0;
  border-bottom: 1px solid #e1c8b3;
  border-right: 1px solid #e1c4b3;
  padding: 3px 4px 3px 4px;
  margin: 2px 2px 2px 0;
  text-decoration: none;
  font-size: 24px;
  line-height: 1.4;
  white-space: nowrap;
}

.tag:hover {
  background-color: #e9dac4;
  border-bottom: 1px solid #e9dcc4;
  border-right: 1px solid #c4dae9;
  text-decoration: none;
}

.cross {
  color: #cd5c5c;
  border-radius: 30px;
  padding: 2px;
}

.cross:hover {
  color: red;
}